.secondlevel-module__FY3fAW__NavSecondLevel {
  background-color: var(--primary-3);
  box-sizing: border-box;
  opacity: 1;
  pointer-events: auto;
  width: 100%;
  max-width: 1440px;
  height: auto;
  z-index: var(--z-index-nav-second-level);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none;
  margin: 0 auto;
  padding: 40px 60px 60px;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  box-shadow: 0 17px 20px -7px rgba(0, 0, 0, .3);
}

@starting-style {
  .secondlevel-module__FY3fAW__NavSecondLevel {
    opacity: 0;
  }
}

.secondlevel-module__FY3fAW__NavSecondLevelTop {
  top: calc(var(--header-height)  - 1.03rem);
}

.secondlevel-module__FY3fAW__NavSecondLevelTopToolbar {
  top: 155px;
}

.secondlevel-module__FY3fAW__NavSecondLevelSticky {
  top: 80px;
}

.secondlevel-module__FY3fAW__NavSecondLevelStickyToolbar {
  top: 116px;
}

.secondlevel-module__FY3fAW__NavSecondLevelAccordion {
  background-color: var(--primary-3);
  box-sizing: border-box;
  pointer-events: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none;
  width: calc(100% - 2rem);
  max-width: 1440px;
  max-height: 820px;
  margin: 0 1rem;
  padding: 1rem 1.25rem;
  position: absolute;
  left: 0;
  overflow: visible;
  box-shadow: 0 17px 20px -7px rgba(0, 0, 0, .3);
}

.secondlevel-module__FY3fAW__NavSecondLevelClose {
  all: unset;
  cursor: pointer;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 20px;
  right: 20px;
}

.secondlevel-module__FY3fAW__NavSecondLevelClose:hover g {
  stroke: var(--secondary-1);
  transition: all .1s ease-in-out;
}

.secondlevel-module__FY3fAW__secondLevelContent {
  column-gap: var(--grid-gap-large);
  z-index: var(--z-index-nav-second-level);
  grid-template-columns: 25% 45% 30%;
  display: grid;
}

.secondlevel-module__FY3fAW__secondLevelContentTwoColumns {
  column-gap: var(--grid-gap-xlarge);
  grid-template-columns: 25% 75%;
  display: grid;
}

.secondlevel-module__FY3fAW__secondLevelContentWiderRight {
  grid-template-columns: 23% 44% 33%;
}

.secondlevel-module__FY3fAW__mainLeftColumn {
  flex-direction: column;
  margin-right: 1rem;
  display: flex;
}

.secondlevel-module__FY3fAW__mainMiddleColumn {
  word-break: break-all;
  justify-content: space-evenly;
}

.secondlevel-module__FY3fAW__mainRightColumn {
  height: inherit;
  border-left: 1px solid var(--secondary-2);
  padding-left: 20px;
}

.secondlevel-module__FY3fAW__overlay {
  background: var(--primary-4);
  opacity: 1;
  height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  top: calc(var(--header-height)  - 1rem);
  z-index: -1;
  width: 100%;
  transition: opacity .2s ease-in-out;
  position: fixed;
}

@starting-style {
  .secondlevel-module__FY3fAW__overlay {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .secondlevel-module__FY3fAW__NavSecondLevelAccordion {
    padding: 1rem 0;
  }
}

.services-module__9i6Rpa__solutionsContainer {
  width: 100%;
}

.services-module__9i6Rpa__mainMiddleColumn .services-module__9i6Rpa__leftColumn .services-module__9i6Rpa__middleColumn .services-module__9i6Rpa__rightColumn {
  width: -moz-fit-content;
  width: fit-content;
}

.services-module__9i6Rpa__mainMiddleColumn .services-module__9i6Rpa__leftColumn .services-module__9i6Rpa__columnTitle {
  font-size: 24px;
}

.services-module__9i6Rpa__mainRightColumnTitle h1, .services-module__9i6Rpa__mainRightColumnTitle h2, .services-module__9i6Rpa__mainRightColumnTitle h3, .services-module__9i6Rpa__mainRightColumnTitle h4, .services-module__9i6Rpa__mainRightColumnTitle h5, .services-module__9i6Rpa__mainRightColumnTitle h6 {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-stretch: normal;
  font-size: var(--font-size-lg);
  letter-spacing: normal;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.imagetextbox-module__S543fq__imageTextBoxContainer {
  max-width: inherit;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 650px;
  padding: 0;
  overflow: hidden;
}

.imagetextbox-module__S543fq__imageTextBoxContent {
  text-align: center;
  max-width: inherit;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: max-content;
  display: flex;
}

.imagetextbox-module__S543fq__imageTextBoxImage figure {
  z-index: 1;
  object-position: left;
  max-width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.imagetextbox-module__S543fq__imageTextBoxTitle {
  color: var(--primary-2);
  letter-spacing: normal;
  width: inherit;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  font-family: KievitOT, Helvetica Neue, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  line-height: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: all .1s ease-in-out;
}

.imagetextbox-module__S543fq__imageTextBoxTitle h1, .imagetextbox-module__S543fq__imageTextBoxTitle h2, .imagetextbox-module__S543fq__imageTextBoxTitle h3, .imagetextbox-module__S543fq__imageTextBoxTitle h4, .imagetextbox-module__S543fq__imageTextBoxTitle h5, .imagetextbox-module__S543fq__imageTextBoxTitle h6 {
  margin: 0;
  padding: 0;
}

.imagetextbox-module__S543fq__imageTextBoxText {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  letter-spacing: normal;
  width: inherit;
  word-break: keep-all;
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.22;
}

.imagetextbox-module__S543fq__imageTextBoxContent a {
  height: 42px;
}

.locations-module__xHEeha__solutionsContainer {
  width: 100%;
}

.locations-module__xHEeha__mainRightColumnTitle h1, .locations-module__xHEeha__mainRightColumnTitle h2, .locations-module__xHEeha__mainRightColumnTitle h3, .locations-module__xHEeha__mainRightColumnTitle h4, .locations-module__xHEeha__mainRightColumnTitle h5, .locations-module__xHEeha__mainRightColumnTitle h6 {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-stretch: normal;
  font-size: var(--font-size-2xl);
  letter-spacing: normal;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.locations-module__xHEeha__LocationsArrowLinkList {
  margin-left: 6rem;
}

.tipstricks-module__gYhppq__solutionsContainer {
  width: 100%;
}

.tipstricks-module__gYhppq__mainRightColumnTitle h1, .tipstricks-module__gYhppq__mainRightColumnTitle h2, .tipstricks-module__gYhppq__mainRightColumnTitle h3, .tipstricks-module__gYhppq__mainRightColumnTitle h4, .tipstricks-module__gYhppq__mainRightColumnTitle h5, .tipstricks-module__gYhppq__mainRightColumnTitle h6 {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-stretch: normal;
  font-size: var(--font-size-2xl);
  letter-spacing: normal;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tipstricks-module__gYhppq__pillsContainer {
  gap: 15px;
  width: 80%;
}

.tipstricks-module__gYhppq__pillsContainer div:last-child button {
  border: 1px solid var(--primary-5);
  background-color: #fff;
}

.tipstricks-module__gYhppq__pillsContainer div:last-child button:hover {
  background-color: var(--primary-2);
}

.tipstricks-module__gYhppq__mainMiddleColumn {
  justify-content: unset;
  margin-left: 1rem;
}

.tipstricks-module__gYhppq__blogPreviews a {
  height: -moz-fit-content;
  height: fit-content;
}

.tipstricks-module__gYhppq__linksContainer a {
  height: 30px;
}

